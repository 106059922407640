import { useEffect, useState } from "react";
import { keyword_evaluate, readability_evaluate, originality_evaluate } from "../../utils/utils";
import avgScoreIcon from '../../assets/Avg score.svg';
import tickpng from '../../assets/red.svg';
import initialIcon from '../../assets/initial.svg';



const Builder = ({ seoScore, bar }) => {

    const [guideVisible, setGuideVisible] = useState(true);
    const handleHide = () => {
        localStorage.setItem("guideline", false);
        setGuideVisible(false)
    }
    useEffect(() => {
        const Consent = localStorage.getItem("guideline");
        if (Consent) {
            setGuideVisible(false);
        }
    }, []);
    return (
        <>
            <div className="pdng">
                <div className="score">SEO score</div>
                <div className="flexBar">
                    <div className="progressBar">
                        <div className="progressBarFillImagesclr1" style={{ width: `${bar}%` }}>

                            <img className="avgScore" src={avgScoreIcon} alt="" />
                        </div>


                        <div className="showAvgMsgPositionAdjust">
                        {guideVisible && <div className="random-avg-popover-content-right">
                            <div>
                                <div className="firstAvgProjectText">
                                    Average SERP  score
                                </div>
                                <div className="firstAvgProjectPara">
                                    Benchmark of top-ranking pages. Aim to exceed for better visibility
                                </div>
                            </div>
                            <div className="showAvgMsgFotterFlex">
                                <div className="showAvgMsgCheckBoxButton" onClick={handleHide}>
                                    Ok, Got it
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    </div>
                    
                    <div className="perc">{seoScore.total_score || 0}%</div>
                </div >
            </div>


            <div className="scoring">
                <div className="brdr">
                    <div className="keyword-class">Keywords</div>
                    <div className="flexScore">
                        <div className="detail-page-keyword">{seoScore.keyword_density || 0 }%</div>
                        {keyword_evaluate(seoScore.keyword_density || 0) ? <img src={tickpng} alt="" className="seo-img" /> : <img src={initialIcon} alt="" />}
                    </div>
                </div>
                <div className="brdr">
                    <div className="keyword-class">Readability</div>
                    <div className="flexScore">
                        <div className="detail-page-keyword">{seoScore.readability_score || 0 }</div>
                        {readability_evaluate(seoScore.readability_score || 0) ? <img src={tickpng} alt="" className="seo-img" /> : <img src={initialIcon} alt="" />}
                    </div>
                </div>
                <div className="brdr-1">
                    <div className="keyword-class">Originality</div>
                    <div className="flexScore">
                        <div className="detail-page-keyword">{seoScore.originality || "-"}</div>
                        {originality_evaluate(seoScore.originality ? seoScore.originality : "") ? <img src={tickpng} alt="" className="seo-img" /> : <img src={initialIcon} alt="" />}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Builder;