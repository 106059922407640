import { toast } from "react-toastify";

const emailValidate = (email) => {
    const emailRegex = new RegExp(process.env.REACT_APP_EMAIL_REGEX);
    return emailRegex.test(email);
};

const isValidPassword = (password) => {
    return password.length >= 6;
};


const StatusEnum = Object.freeze({
    DONE: "Done",
    IN_PROGRESS: "In Progress",
    WAITING_FOR_APPROVAL: "Waiting for Approval",
    PUBLISHED: "Published",
});

const getColorForValue = (kd) => {
    if (kd >= 0 && kd <= 9) {
        return 'green';
    } else if (kd >= 10 && kd <= 19) {
        return 'lightgreen';
    } else if (kd >= 20 && kd <= 29) {
        return 'yellowgreen'; 
    } else if (kd >= 30 && kd <= 39) {
        return 'yellow';
    } else if (kd >= 40 && kd <= 49) {
        return 'orange';
    } else if (kd >= 50 && kd <= 59) {
        return 'orangered';
    } else if (kd >= 60 && kd <= 69) {
        return 'red';
    } else if (kd >= 70 && kd <= 79) {
        return 'darkred';
    } else if (kd >= 80 && kd <= 89) {
        return 'darkred';
    } else if (kd >= 90 && kd <= 99) {
        return 'firebrick';
    } else if (kd === 100) {
        return 'darkred'; 
    } else {
        return 'green';
    }
};

const countWords = (html) => {
    const text = html? html.replace(/<[^>]*>/g, ' ').trim() : "";
    const words = text.split(/\s+/);
    return words.length > 0 && words[0] !== '' ? words.length : 0;
};

const keyword_evaluate = (keyword) => {
    return keyword >= 0.5 && keyword <= 3;

}

const readability_evaluate = (readability) => {
    return readability > 60;
}

const originality_evaluate = (originality) => {
    if (originality === undefined) {
        throw new Error("Originality parameter is missing.");
    }
    return originality.toLowerCase() === "originality";
}
const copyToClipboard = (title, description) => {
    const textToCopy = `${title}\n${description}`.replace(/<[^>]+>/g, '\n');

    if (textToCopy.trim().length > 0) {
        if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    toast.success('Text copied to clipboard');
                })
                .catch(err => {
                    console.error('Error copying text:', err);
                    fallbackCopyTextToClipboard(textToCopy);
                });
        } else {
            fallbackCopyTextToClipboard(textToCopy);
        }
    } else {
        toast.error('Nothing to copy');
    }
};

const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed'; // Avoid scrolling to bottom
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        document.execCommand('copy');
        toast.success('Text copied to clipboard');
    } catch (err) {
        console.error('Fallback: Error copying text:', err);
        toast.error('Failed to copy text');
    }

    document.body.removeChild(textArea);
};

    


export { emailValidate, isValidPassword, StatusEnum, getColorForValue, countWords, keyword_evaluate, readability_evaluate, originality_evaluate, copyToClipboard  }