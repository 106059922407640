import { Input, Modal, Typography } from "antd";
import { useState, useEffect, useRef } from "react";
import tick from "../../assets/tick.svg";
import vector from "../../assets/vector.svg";
import { Button } from "@mui/material";
import api from "../../utils/api";
import { toast } from "react-toastify";
import { StatusEnum } from "../../utils/utils";
import useStore from "../../Store";
import { countWords } from "../../utils/utils";



const SaveModal = ({ open, setOpenSaveModal, docName, setDocName, id, status }) => {
    const [buttonColor, setButtonColor] = useState('black');
    const [selectedStatus, setSelectedStatus] = useState({});
    const [saveName, setSaveName] = useState(docName);
    const [openStatusId, setOpenStatusId] = useState(null);
    const [docStatus, setDocStatus] = useState(null);
    const [docContentObject, setDocContentObject] = useState({});
    const { docContent, clearDocContent, setDocSave } = useStore()
    const [saveStatus, setSaveStatus] = useState("")

    const dropdownRef = useRef(null);
    const statuses = Object.keys(StatusEnum).map(key => ({
        label: StatusEnum[key],
        value: StatusEnum[key],
    }));


    useEffect(() => {
        setSaveStatus(status)
    
    }, [status])

    const toggleActive = (value) => {
        setSelectedStatus(prev => ({ ...prev, [openStatusId]: value }));
        setDocStatus(value);
        setOpenStatusId(null);
    };

    const handleStatusClick = () => {
        setOpenStatusId(true);
    };


    const handleOk = async () => {
        const wordCount = countWords(docContentObject.title + docContentObject.description)
        try {
           await api.patch(
                `/documents/${id}/`, {
                    name: saveName, doc_status: docStatus || saveStatus, document_content: docContentObject, words_count: wordCount

            }
            );
            toast.success("Document saved successfully");
            setDocName(saveName)
            setOpenSaveModal(false);
            // clearDocContent()
            setDocSave(false)
        } catch (err) {
            console.error("Document creation failed:", err);
            toast.error("Error creating Document. Please try again.");
        }
    };

    const handleCancel = () => {
        setOpenSaveModal(false);
        setDocStatus(null)
        setSaveName(docName)
        setOpenStatusId(false);
    };

    const handleNameChange = (e) => {
        setSaveName(e.target.value)
    };

    
    useEffect(() => {
        setSaveName(docName)
        setDocContentObject(docContent)
    }, [open]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenStatusId(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div>
            <Modal
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                width={700}
                footer={[
                    <div className="padding-modal" key="footer">
                        <Button
                            key="submit"
                            type="primary"
                            onClick={handleOk}
                            style={{ backgroundColor: buttonColor, borderColor: buttonColor, color: 'white' }}
                        >
                            Save
                        </Button>
                    </div>,
                ]}
            >
                <div className="padding-modal">
                    <Typography.Title level={2}>Save document</Typography.Title>

                    <div>
                        <Input
                            placeholder="Document Name"
                            value={saveName}
                            onChange={handleNameChange}
                            type="text"
                            required
                            className="rename"
                        />
                    </div>
                    <div ref={dropdownRef} >

                        <div className="status-input" onClick={handleStatusClick}>
                            <p className={`sav-status-dropdown ${docStatus && docStatus.toLowerCase() || saveStatus && saveStatus.length > 0 && saveStatus.toLowerCase() }`}>
                                {docStatus || saveStatus}
                            </p>
                            <img className="status-input-img" src={vector} alt="" />
                        </div>
                        {openStatusId && (
                            <div className="save-publish">
                                {statuses.map((status) => (
                                    <div
                                        key={status.value}
                                        className="save-modal-padding"
                                        onClick={() => toggleActive(status.value)}
                                    >
                                        <div className={`save-modal-flex ${docStatus === status.label ? 'non-active' : 'active-status'}`}>
                                            <div>
                                                {status.label}
                                            </div>
                                            {docStatus === status.value && <img src={tick} alt="Tick" />}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default SaveModal;
